import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';

const LineChartGraph2 = ({ apiUrl, date, plant }) => {
  const [chartData, setChartData] = useState({ xAxis: [], yieldSeries: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(apiUrl, { Date: date, plant });
        const { xAxis, 'Yield kWh': yieldData } = response.data.data;

        setChartData({
          xAxis,
          yieldSeries: yieldData,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 300000); // 300,000 ms = 5 minutes
        return () => clearInterval(intervalId);

  }, [apiUrl, date, plant]);


const getOption = () => ({
    color: ['#32CD32'],
    backgroundColor: 'white',
    grid: {
      left: '8%',
      right: '6%',
      bottom: '3%',
      top: '22%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    legend: {
      data: ['Yield kWh'],
      textStyle: {
        color: 'black',
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.2)',
        },
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.1)',
        },
      },
      axisLabel: {
        color: 'black',
        rotate: 1,
        margin: 15,
      },
      data: chartData.xAxis,
    },
    yAxis: {
      type: 'value',
      name: 'Yield kWh',
      nameLocation: 'end',
      nameGap: 15,
      nameTextStyle: {
        color: 'black',
        fontSize: 12,
        fontWeight: 'bold',
        align: 'center',
      },
      position: 'left',
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.2)',
        },
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.1)',
        },
      },
      axisLabel: {
        color: 'black',
      },
    },
    series: [
      {
        name: 'Yield kWh',
        type: 'line',
        areaStyle: { color: 'rgba(34, 139, 34, 0.7)' }, 
        data: chartData.yieldSeries,
      },
    ],
  });
  
  return <ReactECharts option={getOption()} />;
};

export default LineChartGraph2;
