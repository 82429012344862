import React from 'react';

const ProfitLoss = () => {


  return (

    <div>
   
    <table className="financial-table">
      <thead></thead>
      <tbody>
      <tr>
      <th>Profit and Loss</th>
      <th>Values (S$)</th>
      </tr>
        <tr>
          <td className="section-title">Revenue</td>
          <td></td>
        </tr>
        <tr>
          <td>PPA </td>
          <td>
          <input type="text"/>
          </td>
        </tr>
        <tr>
        <td>RECs </td>
          <td><input type="text" /></td>
        </tr>
        <tr>
        <td>Others </td>
          <td><input type="text" /></td>
        </tr>
        <tr>
          <td>Total Revenue  </td>
          <td><input type="text" readOnly /></td>
        </tr>
        <tr>
          <td className="section-title">Expenditures</td>
          <td></td>
        </tr>
        <tr>
          <td>Yearly Operation and Maintenance Cost (S$/Year)</td>
          <td><input type="text" /></td>
        </tr>
        {/* <tr>
          <td className="section-title">Yearly Operation Insurance Cost </td>
          <td></td>
        </tr> */}
        <tr>
          <td className="indented-item">Public Liability Insurance Premium (S$/Year) </td>
          <td><input type="text" />
          </td>
        </tr>
        <tr>
          <td className="indented-item">All Risk Liability Insurance Premium (S$/Year)
          </td>
          <td><input type="text"/></td>
        </tr>
        <tr>
          <td>Yearly Depreciation (S$)</td>
          <td><input type="text" /></td>
        </tr>
        <tr>
          <td>Total Expenditure (S$)</td>
          <td><input type="text"  /></td>
        </tr>

      </tbody>
    </table>
</div>
);
};

export default ProfitLoss;