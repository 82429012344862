
import React, { useEffect, useState,useCallback } from 'react';
import axios from 'axios';
import SolarImage from '../../../img/solar.jpg';
import GridImage from '../../../img/gridfinal.jpg';
import LoadImage from '../../../img/Load.png';

const SolarPanelDiagram = ({plant,date}) => {

  const [outputPower, setOutputPower] = useState(0);
  const [dailyEnergy, setDailyEnergy] = useState(0);
  const [irradiance, setIrradiance] = useState(0); 
  const [dailyIrradianceMJ, setDailyIrradianceMJ] = useState(0); // For MJ
   

const fetchDailyEnergy = useCallback(async () => {
  try {
      const response = await axios.post('/v1/overview/station-real-kpi/', {
          plant: plant,
          timeZone: date,
      });
      setDailyEnergy(response.data.data.dailyEnergy);
  } catch (error) {
      console.error('There was an error fetching the data!', error);
      setDailyEnergy(0);
  }
}, [plant, date]); 

useEffect(() => {
  setDailyEnergy(0);
  fetchDailyEnergy();
  const intervalId = setInterval(fetchDailyEnergy, 300000); 
  return () => clearInterval(intervalId);
}, [fetchDailyEnergy]);


  
    const fetchPowerData = useCallback(async () => {
      try {
        const response = await fetch('/api/latest/powerout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Date: date,
            plant: plant,
          }),
        });
        
        const data = await response.json();
        if (data.success) {
          setOutputPower(data.data.latestPowerOut);
        }
      } catch (error) {
        console.error('Error fetching output power:', error);
        setOutputPower(0);
      }
    
  }, [plant,date]);

  useEffect(() => {
    setOutputPower(0);
    fetchPowerData();
    const intervalId = setInterval(fetchPowerData, 300000); 
    return () => clearInterval(intervalId);
  }, [fetchPowerData]);



    const fetchDailyIrrad = useCallback(async () => {
      try {
        const response = await fetch('/api/dailyirrad', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            D: date,
            plant: plant,
          }),
        });
        
        const data = await response.json();
      
        if (data && data.dailyIrradianceMJ !== undefined && data.latestIrradMJ !== undefined) 
           {
          setDailyIrradianceMJ(data.dailyIrradianceMJ);  
          setIrradiance(data.latestIrradMJ * 2); 
        }
      } catch (error) {
        console.error('Error fetching output power:', error);
      }
    
  }, [plant, date]);

  
  useEffect(() => {
    fetchDailyIrrad();
    const intervalId = setInterval(fetchDailyIrrad, 300000); 
    return () => clearInterval(intervalId);

  },[fetchDailyIrrad]);


  return (
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 400 200">

     {/* panel to grid */}
      <image x="250" y="110" width="180" height="82" href={SolarImage} />
   
      <path id="path-grid" d="M 300 125 L 240 95" stroke="#000" strokeWidth="0.1" fill="transparent" />
      <text x="380" y="170" fontFamily="Arial" fontSize="12">PV</text>

      <text x="136" y="190" fontFamily="Arial" fontSize="10">Daily Irradiance
      <tspan fontWeight="bold"> {dailyIrradianceMJ} MJ/m²
      
      </tspan>
      </text>

      {/* <tspan x="330" y="200"fontSize="11" fontWeight="bold"> */}
      <text x="280" y="200" fontFamily="Arial" fontSize="10"> Irradiance 
       <tspan fontSize="11" fontWeight="bold">  {irradiance} W/m²
        </tspan></text>


      <text x="330" y="70" fontFamily="Arial" fontSize="10"> Yield Today
      <tspan x="330" y="85"fontSize="11" fontWeight="bold">{dailyEnergy} kWh</tspan>
      </text>

      <text x="330" y="98" fontFamily="Arial" fontSize="10"> Output power 
        <tspan x="330" y="110"fontSize="11" fontWeight="bold">{outputPower} kW</tspan></text>
      

    
     {/* grid to load */}
      <image x="120" y="10" width="155" height="85" href={GridImage} preserveAspectRatio="xMidYMid meet" style={{ imageRendering: 'crisp-edges' }} />
      <line x1="210" y1="90" x2="100" y2="160" stroke="#000" strokeWidth="0.1" markerEnd="url(#arrowhead)" />
      <text x="250" y="28" fontFamily="Arial" fontSize="13">Grid</text>
      <text x="260" y="46" fontFamily="Arial" fontSize="10">Current power</text>

     {/*panel to load */}
      <image x="1" y="100" width="98" height="110" href={LoadImage} />
      <path id="path-load" d="M 320 175 Q 220 180 110 180" stroke="#000" strokeWidth="0.1" fill="transparent"/>
      <text x="-1" y="200" fontFamily="Arial" fontSize="14">Load</text>
      <text x="40" y="198" fontFamily="Arial" fontSize="10">Current power</text>
      
      <g>
        <path d="M 0 0 L -16 -9" stroke="orange" strokeWidth="1.2" markerEnd="url(#arrowhead)" />
        <animateMotion repeatCount="indefinite" dur="5s">
          <mpath href="#path-grid" />
        </animateMotion>
      </g>

      
      <g>
        <path d="M 0 0 L -16 0" stroke="orange" strokeWidth="1.2" markerEnd="url(#arrowhead)" />
        <animateMotion repeatCount="indefinite" dur="5s">
          <mpath href="#path-load" />
        </animateMotion>
      </g>

      <defs>
        <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="10" refY="3.5" orient="auto">
          <polygon points="0 0, 10 3.5, 0 7" fill="orange" />
        </marker>
      </defs>
    </svg>
  );
};

export default SolarPanelDiagram;
