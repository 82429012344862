
import React from 'react';
import { Tooltip } from 'react-tooltip';

const SystemCosts = ({showProjectProfile,toggleProjectProfile, projectInfo, handleInputChange, calculateCosts }) => {

  return (
    <div className="right-column">
      {showProjectProfile ? (
        <button
        style={{
          fontSize: '16px',
          color: 'white',
          backgroundColor: 'green',  
          border: 'none',
          padding: '5px 15px',
          cursor: 'pointer',
          borderRadius: '5px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={toggleProjectProfile}
      >
        Close
      </button>
      

      ) : (
        <>
         
      <span
  style={{
    fontSize: '20px',
    cursor: 'pointer',
    float: "right",
    marginRight: '10px',
  }}
  onClick={toggleProjectProfile}
>
  Project Profile
</span>

<button
  data-tooltip-id="plus-tooltip"
  data-tooltip-content="Click to view the table"
  style={{
    fontSize: '16px',
    color: 'white',
    backgroundColor: 'green',
    border: 'none',
    padding: '5px 15px',
    cursor: 'pointer',
    float: 'right',
    marginRight: '18px',
    borderRadius: '5px',
  }}
  onClick={toggleProjectProfile}
>
  View 
</button>

<Tooltip id="plus-tooltip" place="top" effect="solid" />

        </>
      )}

      {showProjectProfile && (
        <div>
        <div>
            <h4>Project Profile</h4>
        <table>
        <tbody>
          <tr>
            <th>Project Information</th>
            <th>Values</th>
          </tr>
          <tr>
            <td>Power Generation (kWp)</td>
            <td><input type="text" /></td>
          </tr>
          <tr>
            <td>Roof Type</td>
            <td><input type="text"  /></td>
          </tr>
          <tr>
            <td> Project Duration (Years)</td>
            <td><input type="text"  /></td>
          </tr>
          <tr>
            <td>PPA Price (S$/kWh)</td>
           <td><input type="text" /></td>
            {/* <td><input type="text" name="ppaPrice" value={projectInfo.ppaPrice} onChange={handleInputChange} /></td> */}
          </tr>
        </tbody>
      </table>
      </div>
        <div className='systemCost'>
          <h4>System Costs</h4>
          <table>
            <tbody>
              <tr>
                <th>System Costs</th>
                <th>Values</th>
              </tr>
              <tr>
                <td>Solar Panels (S$) (Cost without intercompany charge)</td>
                <td><input type="text" /></td>
               
              </tr>
              <tr>
                <td>Inverters (S$)</td>
                <td><input type="text" /></td>
           
              </tr>
              <tr>
                <td>Smart Loggers & Dashboards (S$)</td>
                <td><input type="text" /></td>
                </tr>
              <tr>
                
                <td>Others (S$)</td>
                <td><input type="text" /></td>
               </tr>
              <tr>
                <td>EPC Costs (S$)</td>
                <td><input type="text" /></td>
            
              </tr>
              <tr>
                <td>Total System Costs (S$)</td>
                <td><input type="text"  /></td>
              
              </tr>
            </tbody>
          </table>
        </div>
        </div>
      )}
    </div>
   
  );
};

export default SystemCosts;
