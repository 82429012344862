

// import React, { useEffect, useState,useCallback } from 'react';
// import axios from 'axios';
// import coalsaved from '../../../img/co.png';
// import co2 from '../../../img/CO2.png';
// import ETP from '../../../img/ETP.png';

// const EnvironmentalBenefits = ({ plant, Date }) => {
  
//   const [coalSaved, setCoalSaved] = useState(0);
//   const [co2Avoided, setCo2Avoided] = useState(0);
//   const [equivalentTreesPlanted, setEquivalentTreesPlanted] = useState(0);

//   // useEffect(() => {
//   //   axios
//   //     .post('/v1/overview/station-real-kpi/', {
//   //       plant: plant,
//   //       timeZone: Date,
//   //     })
//   //     .then((response) => {
//   //       const cumulativeEnergy = response.data.data.cumulativeEnergy;
    
//   //       const coal = 0.4 * cumulativeEnergy;
//   //       const co2 = 0.475 * cumulativeEnergy;
//   //       const trees = (co2 * 1000) / 18.3 / 40;

//   //       setCoalSaved(coal);
//   //       setCo2Avoided(co2);
//   //       setEquivalentTreesPlanted(trees);
//   //     })
//   //     .catch((error) => {
//   //       console.error('There was an error fetching the data!', error);
//   //     });
//   // }, [plant, Date]);

//   const fetchEnvironmentalData = useCallback(async () => {
//     try {
//         const response = await axios.post('/v1/overview/station-real-kpi/', {
//             plant: plant,
//             timeZone: Date,
//         });
//         const cumulativeEnergy = response.data.data.cumulativeEnergy;

//         const coal = 0.4 * cumulativeEnergy;
//         const co2 = 0.475 * cumulativeEnergy;
//         const trees = (co2 * 1000) / 18.3 / 40;

//         setCoalSaved(coal);
//         setCo2Avoided(co2);
//         setEquivalentTreesPlanted(trees);
//     } catch (error) {
//         console.error('There was an error fetching the data!', error);
//     }
// }, [plant,Date]); 

// useEffect(() => {
  
//     fetchEnvironmentalData();
//     const intervalId = setInterval(fetchEnvironmentalData, 300000); 

//     return () => clearInterval(intervalId);
// }, [fetchEnvironmentalData]); 


//   return (
//     <div>
//         <h6>Environmental Benefits</h6>
//       <div className="EB-image-row">
//   <img src={coalsaved} alt="Coal Saved" />
//   <img src={co2} alt="CO2 Avoided" />
//   <img src={ETP} alt="Equivalent Trees Planted" />
// </div>

//       <div>
     
//   <div className="values-row">
//     <div className='coal' >{coalSaved.toFixed(2)} Tons</div>
//     <div className='co2'>{co2Avoided.toFixed(2)} Tons</div>
//     <div className='ETP'>{equivalentTreesPlanted.toFixed(2)} Nos</div>
//   </div>


//   <div className="text-row">
//     <div className='coal'>Coal Saved</div>
//     <div className='co2'>CO2 Avoided</div>
//     <div className='ETP'>Equivalent Trees Planted</div>
//   </div>
// </div>

//     </div>


//   );
// };

// export default EnvironmentalBenefits;

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import coalsaved from '../../../img/co.png';
import co2 from '../../../img/CO2.png';
import ETP from '../../../img/ETP.png';

const EnvironmentalBenefits = ({ plant, Date }) => {
    const [coalSaved, setCoalSaved] = useState(0);
    const [co2Avoided, setCo2Avoided] = useState(0);
    const [equivalentTreesPlanted, setEquivalentTreesPlanted] = useState(0);

    const fetchEnvironmentalData = useCallback(async () => {
        try {
            const response = await axios.post('/v1/overview/station-real-kpi/', {
                plant: plant,
                timeZone: Date,
            });

            const cumulativeEnergy = response.data.data.cumulativeEnergy;

            const coal = 0.4 * cumulativeEnergy;
            const co2 = 0.475 * cumulativeEnergy;
            const trees = (co2 * 1000) / 18.3 / 40;

            setCoalSaved(coal);
            setCo2Avoided(co2);
            setEquivalentTreesPlanted(trees);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
            // Reset values in case of an error
            setCoalSaved(0);
            setCo2Avoided(0);
            setEquivalentTreesPlanted(0);
        }
    }, [plant, Date]);

    useEffect(() => {
        // Reset environmental benefits when the plant changes
        setCoalSaved(0);
        setCo2Avoided(0);
        setEquivalentTreesPlanted(0);

        fetchEnvironmentalData();
        const intervalId = setInterval(fetchEnvironmentalData, 300000);

        return () => clearInterval(intervalId);
    }, [fetchEnvironmentalData, plant, Date]); // Add plant and Date to the dependency array

    return (
        <div>
            <h6>Environmental Benefits</h6>
            <div className="EB-image-row">
                <img src={coalsaved} alt="Coal Saved" />
                <img src={co2} alt="CO2 Avoided" />
                <img src={ETP} alt="Equivalent Trees Planted" />
            </div>

            <div>
                <div className="values-row">
                    <div className='coal'>{coalSaved.toFixed(2)} Tons</div>
                    <div className='co2'>{co2Avoided.toFixed(2)} Tons</div>
                    <div className='ETP'>{equivalentTreesPlanted.toFixed(2)} Nos</div>
                </div>

                <div className="text-row">
                    <div className='coal'>Coal Saved</div>
                    <div className='co2'>CO2 Avoided</div>
                    <div className='ETP'>Equivalent Trees Planted</div>
                </div>
            </div>
        </div>
    );
};

export default EnvironmentalBenefits;
