
import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';

const MonthBarChart = ({ apiUrl, LM, plant }) => {
    const [chartData, setChartData] = useState({
        xAxis: [],
        irradiationSeries: [],
        yieldSeries: [],
    });
    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await axios.post(apiUrl, { plant, LM });

              if (response.data.success) {
                  const { xAxis, 'Irradiation kWh/m2': irradiationData, 'Yield kWh': yieldData } = response.data.data;

                  setChartData({
                      xAxis: xAxis || [], // Ensure empty arrays if data is missing
                      irradiationSeries: irradiationData || [], // Fallback to empty array
                      yieldSeries: yieldData || [], // Fallback to empty array
                  });
              } else {
                  console.error('API response error:', response.data);
                  // Set empty data on failure
                  setChartData({
                      xAxis: [],
                      irradiationSeries: [],
                      yieldSeries: [],
                  });
              }
          } catch (error) {
              console.error('Error fetching data:', error);
              // Set empty data on error
              setChartData({
                  xAxis: [],
                  irradiationSeries: [],
                  yieldSeries: [],
              });
          }
      };

      fetchData();
      const intervalId = setInterval(fetchData, 300000); // 300,000 ms = 5 minutes
        return () => clearInterval(intervalId);

  }, [apiUrl, LM, plant]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await axios.post(apiUrl, {
    //                 plant,
    //                 LM
    //             });
                
    //             if (response.data.success) {
    //                 const { xAxis, 'Irradiation kWh/m2': irradiationData, 'Yield kWh': yieldData } = response.data.data;
                    
    //                 setChartData({
    //                     xAxis,
    //                     irradiationSeries: irradiationData,
    //                     yieldSeries: yieldData,
    //                 });
    //             } else {
    //                 console.error('API response error:', response.data);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchData();
    // }, [apiUrl, LM, plant]);

    const getOption = () => ({
        color: ['#0000ff', '#ff0000'],
        backgroundColor: 'white',
        grid: {
            left: '3%',
            right: '8%',
            bottom: '3%',
            top: '22%',
            containLabel: true,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        legend: {
            data: ['Irradiation kWh/m²', 'Yield kWh'],
        },
        xAxis: {
            type: 'category',
            data: chartData.xAxis,
            axisLabel: {
                color: 'black',
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                },
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                },
            },
        },
        yAxis: [
            {
                type: 'value',
                name: 'Yield kWh',
                nameLocation: 'end',
                nameGap: 15, 
                nameTextStyle: {
                  color: 'black',
                  fontSize: 12,
                  fontWeight: 'bold',
                 align: 'center',
                },
                position: 'left',
                axisLine: {
                  lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                  },
                },
                splitLine: {
                  lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                  },
                },
                axisLabel: {
                  color: 'black',
                },
              },
              {
                type: 'value',
                name: 'Irradiation kWh/m²',
                nameLocation: 'end',
                nameGap: 15, 
                nameTextStyle: {
                  color: 'black',
                  fontSize: 12,
                  fontWeight: 'bold',
                  align: 'center',
                
                },
                position: 'right',
                axisLine: {
                  lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                  },
                },
                splitLine: {
                  lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                  },
                },
                axisLabel: {
                  color: 'black',
                },
              },
            ],
        series: [
            {
                name: 'Irradiation kWh/m²',
                type: 'bar',
                data: chartData.irradiationSeries,
                yAxisIndex: 1,
            },
            {
                name: 'Yield kWh',
                type: 'bar',
                data: chartData.yieldSeries,
                yAxisIndex: 0,
            },
        ],
    });

    return <ReactECharts option={getOption()} />;
};

export default MonthBarChart;
