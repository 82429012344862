import React from 'react';
import './SolarTable.css'; 

const RECTable = () => {
  return (
    <div>
    <h5 style={{fontWeight:"bold"}}>REC Revenue</h5>
    <table className="solar-table">
      <thead>
        <tr>
        <th rowSpan="2">Items</th>
          <th colSpan="2" className="solar-header">Monthly</th>
          <th colSpan="2" className="solar-header">Yearly</th>
          <th className="solar-header">Year to Date</th>
        </tr>
        <tr>
          
          <th>Period</th>
          <th>Values</th>
          <th>Period</th>
          <th>Value</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowSpan="12">REC Revenue (S$)</td>
          <td>Jan</td>
          <td>  <input type="text"/></td>
          <td rowSpan="12">2023</td>
          <td rowSpan="12"><input type="text"/></td>
          <td rowSpan="12"><input type="text"/></td>
        </tr>
        {['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month) => (
          <tr key={month}>
            <td>{month}</td>
            <td>  <input type="text"/></td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
};

export default RECTable;
