

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LineChart from './Graph/LineChart1';
import BarChart from './Graph/MonthBarChart';
import YearBarChart from './Graph/YearBarChart';
import LifetimeBarChart from './Graph/LifetimeBarChart';
import DatePicker from './Graph/DatePicker';
import Dropdown from './Graph/Dropdown';
import EnergyValue from './Graph/EnergyValue';
//yield
import YieldOverview from './YieldOverview';
//Graph 2
import DatePicker2 from './Graph2/DatePicker2';
import Dropdown2 from './Graph2/Dropdown2';
import EnergyValueGraph2 from './Graph2/EnergyValueGraph2';
import MonthBarChartGraph2 from './Graph2/MonthBarChartGraph2';
import YearBarChartGraph2 from './Graph2/YearBarChartGraph2';
import LineChartGraph2 from './Graph2/LineChartGraph2';
import LifetimeChartGraph2 from './Graph2/LifetimeChartGraph';

//flow diagram
import FlowDiagram from './FlowDiagram/FlowDiagram';
//env Benefits
import EnvBenefits from './EnvironmentalBenefits/EnvBenefits';
//Alarm
import Alarm from './Alarm/Alarm';

// const userType= localStorage.getItem('userType');

const getFormattedDateForBackend = (currentDate, viewOption) => {
    const date = new Date(currentDate);
    
    if (viewOption === 'Month') {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-01`;
    } else if (viewOption === 'Year') {
        return `${date.getFullYear()}-01-01`;
    }
    
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

const OverviewGraph1 = ({ selectedPlant,userType}) => {
    const [currentDate1, setCurrentDate1] = useState(() => {
        const now = new Date();
        return now.toISOString().split('T')[0]; // Default to current date (YYYY-MM-DD)
    });
    const [viewOption1, setViewOption1] = useState('Day');
    const [energyValue1, setEnergyValue1] = useState(null);
    const [dailyIrradiance1, setDailyIrradiance1] = useState(null);

    const [currentDate2, setCurrentDate2] = useState(new Date().toISOString().split('T')[0]);
    const [viewOption2, setViewOption2] = useState('Day');
    const [energyValue2, setEnergyValue2] = useState(null);

    // Handler functions for Graph 1
    const handleDateChange1 = (event) => {
        setCurrentDate1(event.target.value);
    };

    const handleOptionChange1 = (event) => {
        const newViewOption = event.target.value;
        setViewOption1(newViewOption); // Update view option

        const today = new Date();
        if (newViewOption === 'Day') {
            setCurrentDate1(today.toISOString().split('T')[0]); // Set to today's date (YYYY-MM-DD)
        } else if (newViewOption === 'Month') {
            setCurrentDate1(`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}`); // Set to current month (YYYY-MM)
        } else if (newViewOption === 'Year') {
            setCurrentDate1(`${today.getFullYear()}`); // Set to current year (YYYY)
        }
    };
    // graph2
    const handleDateChange2 = (event) => {
        setCurrentDate2(event.target.value);
    };

    const handleOptionChange2 = (event) => {
        const newViewOption = event.target.value;
        setViewOption2(newViewOption); // Update view option

        const today = new Date();
        if (newViewOption === 'Day') {
            setCurrentDate2(today.toISOString().split('T')[0]); // Set to today's date (YYYY-MM-DD)
        } else if (newViewOption === 'Month') {
            setCurrentDate2(`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}`); // Set to current month (YYYY-MM)
        } else if (newViewOption === 'Year') {
            setCurrentDate2(`${today.getFullYear()}`); // Set to current year (YYYY)
        }
    };

    // useEffect(() => {
    //     const fetchEnergyData1 = async () => {
    //         try {
    //             const formattedDate = getFormattedDateForBackend(currentDate1, viewOption1);
    //             const energyResponse = await axios.post('/v1/overview/station-real-kpi/', {
    //                 plant: selectedPlant,
    //                 timeZone: formattedDate,
    //             });
    //             const energyData = energyResponse.data.data;


    //             switch (viewOption1) {
    //                 case 'Day':
    //                     setEnergyValue1(energyData.dailyEnergy);
    //                     break;
    //                 case 'Month':
    //                     setEnergyValue1(energyData.monthEnergy);
    //                     break;
    //                 case 'Year':
    //                     setEnergyValue1(energyData.yearEnergy);
    //                     break;
    //                 case 'Lifetime':
    //                     setEnergyValue1(energyData.cumulativeEnergy);
    //                     break;
    //                 default:
    //                     setEnergyValue1(null);
    //                     break;
    //             }

    //             if (viewOption1 === 'Day') {
    //                 const irradResponse = await axios.post('/api/dailyirrad', {
    //                     plant: selectedPlant,
    //                     D: formattedDate,
    //                 });
    //                 const irradData = irradResponse.data;
    //                 setDailyIrradiance1(irradData.dailyIrradianceKWh);
    //             } else {
    //                 setDailyIrradiance1(null);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching data for Graph 1:', error.message);
    //         }
    //     };

    //     fetchEnergyData1();

    //     const intervalId = setInterval(fetchEnergyData1, 300000); // 300,000 ms = 5 minutes
    //     return () => clearInterval(intervalId);

    // }, [currentDate1, viewOption1, selectedPlant]);

    useEffect(() => {
        const fetchEnergyData1 = async () => {
            try {
                const formattedDate = getFormattedDateForBackend(currentDate1, viewOption1);
                //console.log(`Fetching energy data for ${formattedDate}...`); // Log for debugging
                
                const energyResponse = await axios.post('/v1/overview/station-real-kpi/', {
                    plant: selectedPlant,
                    timeZone: formattedDate,
                });
                const energyData = energyResponse.data.data;
    
                switch (viewOption1) {
                    case 'Day':
                        setEnergyValue1(energyData.dailyEnergy);
                        break;
                    case 'Month':
                        setEnergyValue1(energyData.monthEnergy);
                        break;
                    case 'Year':
                        setEnergyValue1(energyData.yearEnergy);
                        break;
                    case 'Lifetime':
                        setEnergyValue1(energyData.cumulativeEnergy);
                        break;
                    default:
                        setEnergyValue1(null);
                        break;
                }
    
                if (viewOption1 === 'Day') {
                    const irradResponse = await axios.post('/api/dailyirrad', {
                        plant: selectedPlant,
                        D: formattedDate,
                    });
                    const irradData = irradResponse.data;
                    setDailyIrradiance1(irradData.dailyIrradianceKWh);
                } else {
                    setDailyIrradiance1(null);
                }
            } catch (error) {
                console.error('Error fetching data for Graph 1:', error.message);
                setEnergyValue1(0);
        setDailyIrradiance1(0);
            }
        };
        setEnergyValue1(0);
        setDailyIrradiance1(0);
        fetchEnergyData1(); // Initial fetch
    
        const intervalId = setInterval(fetchEnergyData1, 300000); // Fetch every 5 minutes
        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [currentDate1, viewOption1, selectedPlant]); // Only run when these values change
    


    //graph2
    useEffect(() => {
        const fetchEnergyData2 = async () => {
            try {
                const formattedDate = getFormattedDateForBackend(currentDate2, viewOption2);
                const energyResponse = await axios.post('/v1/overview/station-real-kpi/', {
                    plant: selectedPlant,
                    timeZone: formattedDate,
                });
                const energyData = energyResponse.data.data;

                if (!energyData || 
                    (viewOption2 === 'Day' && !energyData.dailyEnergy) ||
                    (viewOption2 === 'Month' && !energyData.monthEnergy) ||
                    (viewOption2 === 'Year' && !energyData.yearEnergy) ||
                    (viewOption2 === 'Lifetime' && !energyData.cumulativeEnergy)) {
                    setEnergyValue2(null); 
                    return; 
                }

                switch (viewOption2) {
                    case 'Day':
                        setEnergyValue2(energyData.dailyEnergy);
                        break;
                    case 'Month':
                        setEnergyValue2(energyData.monthEnergy);
                        break;
                    case 'Year':
                        setEnergyValue2(energyData.yearEnergy);
                        break;
                    case 'Lifetime':
                        setEnergyValue2(energyData.cumulativeEnergy);
                        break;
                    default:
                        setEnergyValue2(null);
                        break;
                }

              
            } catch (error) {
                console.error('Error fetching data for Graph 2:', error.message);
                setEnergyValue2(0);
            }
        };
        setEnergyValue2(0);
        fetchEnergyData2();
        const intervalId = setInterval(fetchEnergyData2, 300000); // 300,000 ms = 5 minutes
        return () => clearInterval(intervalId);

    }, [currentDate2, viewOption2, selectedPlant]);


    const renderGraph1 = () => {
      
        if (viewOption1 === 'Day') {
            return <LineChart apiUrl={`/api/dailypower`} date={currentDate1} plant={selectedPlant} />;
        } else if (viewOption1 === 'Month') {
            const formattedMonth1 = `${currentDate1.split('-')[0]}-${String(currentDate1.split('-')[1]).padStart(2, '0')}`;
            return <BarChart apiUrl={`/api/monthlypower`} LM={formattedMonth1} plant={selectedPlant} />;
        } else if (viewOption1 === 'Year') {
            return <YearBarChart apiUrl={`/api/yearlypower`} Date={currentDate1} plant={selectedPlant} />;
        } else if (viewOption1 === 'Lifetime') {
            return <LifetimeBarChart apiUrl={`/api/lifetimepower`} plant={selectedPlant} />;
        }
        return null;
    };

//console.log("overview:",userType);


    //graph2
    const renderGraph2 = () => {
        if (!energyValue2) {
            return <div>No data available for the selected date.</div>; // Display a message or return null
        }
        if (viewOption2 === 'Day') {
            return <LineChartGraph2
            apiUrl={`/api/dayenergygraph`}
            date={currentDate2}
            plant={selectedPlant}
        />;
        } else if (viewOption2 === 'Month') {
            const formattedMonth2 = `${currentDate2.split('-')[0]}-${String(currentDate2.split('-')[1]).padStart(2, '0')}`;
            return <MonthBarChartGraph2
            apiUrl={`/api/monthenergygraph`}
            Date={formattedMonth2}
            plant={selectedPlant}
        />
        } else if (viewOption2 === 'Year') {
            return <YearBarChartGraph2
            apiUrl={`/api/yearenergygraph`}
            year={currentDate2}
            plant={selectedPlant}
        />
        } else if (viewOption2 === 'Lifetime') {
            return  <LifetimeChartGraph2
            apiUrl={`/api/lifetimeenergygraph`}
            plant={selectedPlant}
        />
        }
        return null;
    };
console.log("selected:",selectedPlant);
    const getDisplayPlantName = (plant, userType) => {
        if (userType === '4' || userType===4) {
            return 'Plant A';
        } else if (userType === '5' || userType===5) {
        
            switch (plant) {
                case 'Bodyknits':
                    return 'Plant A'; 
                case 'Sweelee':
                    return 'Plant B';
                default:
                    return plant;
            }
        }
        

        
        switch (plant) {
            case 'Bodyknits':
                return 'Bodynits';
            case 'Sweelee':
                return 'Swee Lee';
            default:
                return plant;
        } 
    };
    const displayPlantName = getDisplayPlantName(selectedPlant, userType);
    
    return (
        <div className="main-container">
    <div className="left-column">
        <div className="fixed-header">
            <h1 className="plant-header">
                <strong>
                   <span id="Plant2">{displayPlantName}</span> Overview
                </strong>
            </h1>

        </div>

        <div className="main-div">
            <YieldOverview plantName={selectedPlant} currentDate={new Date().toISOString().split('T')[0]} />
        </div>
       
        <div className="flow-diagram"><FlowDiagram plant={selectedPlant} date={new Date().toISOString().split('T')[0]}/></div>
        
        {userType !== 1 && userType !== 3   &&  userType !== 4 &&
         userType !== '1' && userType !== '3'   &&  userType !== '4' &&(
       <div className='alarm-div'><Alarm plant={selectedPlant}/></div>
        )}

       {/* <div className='env-benefits'> */}
       <div
  className={
    userType === 2 || userType === 9 || userType==='2' || userType ==='9' ||userType==='5' || userType===5
      ? 'env-benefits'
      : userType === 1 || userType === 4 || userType === 3 || userType==='1'||userType==='4'|| userType==='3'
      ? 'environ-benefits'
      : ''
  }
>

        <EnvBenefits plant={selectedPlant} Date={new Date().toISOString().split('T')[0]}/>
        </div>
        
        
        <div className="main-div2">
            <div className="date-picker-dropdown-container">
                <DatePicker
                    viewOption={viewOption1}
                    currentDate={currentDate1}
                    handleDateChange={handleDateChange1}
                />
                <Dropdown
                    viewOption={viewOption1}
                    handleOptionChange={handleOptionChange1}
                />
            </div>

            <EnergyValue
                viewOption={viewOption1}
                energyValue={energyValue1}
                dailyIrradiance={dailyIrradiance1}
            />

            {renderGraph1()}
        </div>
        <div className="right-column">
        <div className="main-div3">
            {/* Graph 2 */}
            <div className="date-picker-dropdown-container">
                <DatePicker2
                    viewOption={viewOption2}
                    currentDate={currentDate2}
                    handleDateChange={handleDateChange2}
                />
                <Dropdown2
                    viewOption={viewOption2}
                    handleOptionChange={handleOptionChange2}
                />
            </div>

            <EnergyValueGraph2
                viewOption={viewOption2}
                energyValue={energyValue2}
            />
              {renderGraph2()}

        </div>
    </div>
    </div>
</div>

    );
};

export default OverviewGraph1;
