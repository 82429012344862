// import React, { useEffect, useState } from 'react';
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUserCheck, faKey, faUserCog } from '@fortawesome/free-solid-svg-icons';
// import solarpanel from '../img/solarpanel (2).png';
// import '../styles/Header.css';


// // Define display name mapping
// const displayNameMapping = {
//   'Bodyknits': 'Bodynits',
//   'Sweelee': 'SweeLee',
//   'SGWireman':'SG Wireman',
//   'mx':'mx',
//   'Plant A': 'Plant A',
// };

// const Header = ({ selectedPlant, setSelectedPlant, onLogout }) => {
//   const [availablePlants, setAvailablePlants] = useState([]);
  
//   // Set available plants and initial selected plant based on userId
//   useEffect(() => {
//     const userId = localStorage.getItem('userId');
//     const userType=localStorage.getItem('userType');
    
//     if (userId === '1') {
//       setAvailablePlants(['Bodyknits']);
//       if (selectedPlant !== 'Bodyknits') {
//         setSelectedPlant('Bodyknits');
//       }
//     } else if (userId === '2') {
//       setAvailablePlants(['Sweelee']);
//       if (selectedPlant !== 'Sweelee') {
//         setSelectedPlant('Sweelee');
//       }


//     } else if (userId === '25') {
//       setAvailablePlants(['SGWireman']);
//       if (selectedPlant !== 'SGWireman') {
//         setSelectedPlant('SGWireman');
//       }
//     }else if (userId === '37') {
//       setAvailablePlants(['mx']);
//       if (selectedPlant !== 'mx') {
//         setSelectedPlant('mx');
//       }
//     }else if (userType === '4') {
//       setAvailablePlants(['Bodyknits']);
//       setSelectedPlant('Bodyknits');
//      } else {
//       setAvailablePlants(['Bodyknits', 'Sweelee','SGWireman','mx']); 
    
//       if (!['Bodyknits', 'Sweelee','SGWireman','mx'].includes(selectedPlant)) {
//         setSelectedPlant('Bodyknits');
//       }
//     }
//   }, [setSelectedPlant, selectedPlant]); 

//   const handlePlantChange = (event) => {
//     const newPlant = event.target.value;
//     if (selectedPlant !== newPlant) {
//       setSelectedPlant(newPlant);
//     }
//   };

//   const handleLogoutClick = () => {
//     onLogout();
//   };

//   const iconStyle = {
//     width: '20%',
//   };
//   const userIconStyle = {
//     fontSize: '30px',
//   };
//   const userId = localStorage.getItem('userId'); 
//   //console.log("userid",userId);
//   const userType=localStorage.getItem('userType');
//   // console.log("userType:",userType);

//   return (
//     <Navbar bg="light" expand="lg" className="navbar-custom">
//       <Navbar.Toggle aria-controls="basic-navbar-nav" />
//       <Navbar.Collapse id="basic-navbar-nav">
//         <Nav className="mr-auto">
//           <NavDropdown title="Home" id="basic-nav-dropdown">
//             <NavDropdown.Item as={Link} to="/overview">Overview</NavDropdown.Item>
        
//             <NavDropdown.Item as={Link} to="/map">Mapview</NavDropdown.Item>
          
//           </NavDropdown>

//           {userType !== '1' && userType !== '3'  && (
//             <NavDropdown title="Monitoring" id="basic-nav-dropdown">
//               <NavDropdown.Item as={Link} to="/reports">Monitoring</NavDropdown.Item>
//             </NavDropdown>
//           )}

//           {userType !== '1' && userType !== '2' && userType !== '3' && userType !=='4' && (
//             <NavDropdown title="Financial Module" id="basic-nav-dropdown">
//               <NavDropdown.Item as={Link} to="/finance">Financial Module</NavDropdown.Item>
//             </NavDropdown>
//           )}

//           {userType !== '1' && userType !== '3' && userType !=='4' && (
//             <NavDropdown title="Alarm" id="basic-nav-dropdown">
//               <NavDropdown.Item as={Link} to="/alarm">Alarm</NavDropdown.Item>
//             </NavDropdown>
//           )}

//           {userType !== '1' && userType !== '2' && userType !== '3' &&  userType!=='4' && (
//            <NavDropdown title="Plants" id="basic-nav-dropdown">
//             <NavDropdown.Item as={Link} to="/plant">Plant Management</NavDropdown.Item>
//             <NavDropdown.Item as={Link} to="/device">Device Management</NavDropdown.Item>
//           </NavDropdown>
//              )}

//           <NavDropdown title="Logout" id="basic-nav-dropdown">
//             <NavDropdown.Item as={Link} to="/" onClick={handleLogoutClick}>Logout</NavDropdown.Item>
//           </NavDropdown>
//         </Nav>

//         <Nav className="plant-select">
//           {userId === '1' && (
//             <>
//               <Nav.Item className="nav-item"> 
//                 <FontAwesomeIcon icon={faUserCheck} /> Bodyknits
//               </Nav.Item>
//               <Nav.Item>
//                 <FontAwesomeIcon icon={faKey} /> User
//               </Nav.Item>
//             </>
//           )}
//           {userId === '2' && (
//             <>
//               <Nav.Item className="nav-item"> 
//                 <FontAwesomeIcon icon={faUserCheck} /> Sweelee
//               </Nav.Item>
//               <Nav.Item>
//                 <FontAwesomeIcon icon={faKey} /> User
//               </Nav.Item>
//             </>
//           )}

//           {userType === '4' && (
//             <>
//               <Nav.Item className="nav-item"> 
//                 <FontAwesomeIcon icon={faUserCheck} /> TRE-Solutions
//               </Nav.Item>
//               <Nav.Item>
//                 <FontAwesomeIcon icon={faKey} /> User
//               </Nav.Item>
//             </>
//           )}
//           {userType === '9' && (
//             <>
//               <Nav.Item className="nav-item"> 
//                 <FontAwesomeIcon icon={faUserCheck} /> TRE-Solutions
//               </Nav.Item>
//               <Nav.Item>
//                 <FontAwesomeIcon icon={faKey} /> : Super Admin
//               </Nav.Item>
//             </>
//           )}
//           {userType === '3' && (
//             <>
//               <Nav.Item className="nav-item"> 
//                 <FontAwesomeIcon icon={faUserCheck} /> Owner
//               </Nav.Item>
//               <Nav.Item>
//                 <FontAwesomeIcon icon={faKey} /> Owner
//               </Nav.Item>
//             </>
//           )}
//           {userType === '2' && (
//             <>
//               <Nav.Item className="nav-item"> 
//                 <FontAwesomeIcon icon={faUserCheck} /> Admin
//               </Nav.Item>
//               <Nav.Item>
//                 <FontAwesomeIcon icon={faKey} /> Admin
//               </Nav.Item>
//             </>
//           )}

//           {/* <Nav.Item>
//             <img src={solarpanel} alt="solarpanel" style={iconStyle} />
//             :
//             <select id="PlantAdmin" onChange={handlePlantChange} value={selectedPlant} className="form-control">
//               {availablePlants.map((plant) => (
//                 <option key={plant} value={plant}>
//                   {displayNameMapping[plant] || plant}
//                 </option>
//               ))}
//             </select>
//           </Nav.Item> */}

// <Nav.Item>
//   <img src={solarpanel} alt="solarpanel" style={iconStyle} />
//   :
//   <select
//     id="PlantAdmin"
//     onChange={handlePlantChange}
//     value={userType === '4' ? 'Bodyknits' : selectedPlant} // Always set to Bodyknits for userType 4
//     className="form-control"
//   >
//     {availablePlants.map((plant) => (
//       <option key={plant} value={plant}>
//         {plant === 'Bodyknits' && userType === '4' ? 'Plant A' : displayNameMapping[plant] || plant}
//       </option>
//     ))}
//   </select>
// </Nav.Item>

//           {userType !== '1' && userType !== '2' && userType !== '3' &&  userType!=='4' && (
//             <Nav.Item className="nav-item"> 
//               <OverlayTrigger
//                 placement="bottom"
//                 overlay={<Tooltip id="user-management-tooltip">User Management</Tooltip>}
//               >
//                 <Link to="/userinfo">
//                   <FontAwesomeIcon icon={faUserCog} style={userIconStyle} />
//                 </Link>
//               </OverlayTrigger>
//             </Nav.Item>
//           )}
//         </Nav>
//       </Navbar.Collapse>
//     </Navbar>
//   );
// };

// export default Header;

import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation to track current path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faKey, faUserCog } from '@fortawesome/free-solid-svg-icons';
import solarpanel from '../img/solarpanel (2).png';
import '../styles/Header.css';

const displayNameMapping = {
  'Bodyknits': 'Bodynits',
  'Sweelee': 'SweeLee',
  'SGWireman': 'SG Wireman',
  'mx': 'mx',
  'Plant A': 'Plant A',
  'Plant B':'Plant B'
};

const Header = ({ selectedPlant, setSelectedPlant, onLogout }) => {
  const [availablePlants, setAvailablePlants] = useState([]);
  const [isFinanceModuleActive, setIsFinanceModuleActive] = useState(false); // New state variable
  const location = useLocation(); // Hook to get current location

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const userType = localStorage.getItem('userType');

    if (userId === '1') {
      setAvailablePlants(['Bodyknits']);
      if (selectedPlant !== 'Bodyknits') {
        setSelectedPlant('Bodyknits');
      }
    } else if (userId === '2') {
      setAvailablePlants(['Sweelee']);
      if (selectedPlant !== 'Sweelee') {
        setSelectedPlant('Sweelee');
      }
    } else if (userId === '25') {
      setAvailablePlants(['SGWireman']);
      if (selectedPlant !== 'SGWireman') {
        setSelectedPlant('SGWireman');
      }
    } else if (userId === '37') {
      setAvailablePlants(['mx']);
      if (selectedPlant !== 'mx') {
        setSelectedPlant('mx');
      }
    } else if (userType === '4') {
      setAvailablePlants(['Bodyknits']);
      setSelectedPlant('Bodyknits');
    } else if (userType === '5') { 
      setAvailablePlants(['Bodyknits', 'Sweelee']);
      if (selectedPlant !== 'Bodyknits' && selectedPlant !== 'Sweelee') {
        setSelectedPlant('Bodykniyts'); // Default to Plant A
      }
    } 
     else {
      setAvailablePlants(['Bodyknits', 'Sweelee', 'SGWireman', 'mx']);
      if (!['Bodyknits', 'Sweelee', 'SGWireman', 'mx'].includes(selectedPlant)) {
        setSelectedPlant('Bodyknits');
      }
    }
  }, [setSelectedPlant, selectedPlant]);

  // Check if the current path is the financial module path
  useEffect(() => {
    if (location.pathname === '/finance' ||
      location.pathname === '/plant' ||
      location.pathname ==='/device' ||
      location.pathname === '/userinfo'
    ) {
      setIsFinanceModuleActive(true);
    } else {
      setIsFinanceModuleActive(false);
    }
  }, [location.pathname]);

  const handlePlantChange = (event) => {
    const newPlant = event.target.value;
    if (selectedPlant !== newPlant) {
      setSelectedPlant(newPlant);
    }
  };

  const handleLogoutClick = () => {
    onLogout();
  };

  const iconStyle = {
    width: '20%',
  };
  const userIconStyle = {
    fontSize: '30px',
  };
  const userId = localStorage.getItem('userId');
  const userType = localStorage.getItem('userType');

  return (
    <Navbar bg="light" expand="lg" className="navbar-custom">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <NavDropdown title="Home" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/overview">Overview</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/map">Mapview</NavDropdown.Item>
          </NavDropdown>

          {userType !== '1' && userType !== '3' && userType !=='4' && (
            <NavDropdown title="Monitoring" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/reports">Monitoring</NavDropdown.Item>
            </NavDropdown>
          )}

          {userType !== '1' && userType !== '2' && userType !== '3' && userType !== '4' && userType !=='5' && (
            <NavDropdown title="Financial Module" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/finance">Financial Module</NavDropdown.Item>
            </NavDropdown>
          )}

          {userType !== '1' && userType !== '3' && userType !== '4' && (
            <NavDropdown title="Alarm" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/alarm">Alarm</NavDropdown.Item>
            </NavDropdown>
          )}

          {userType !== '1' && userType !== '2' && userType !== '3' && userType !== '4' && userType !=='5' && (
           <NavDropdown title="Plants" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/plant">Plant Management</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/device">Device Management</NavDropdown.Item>
          </NavDropdown>
             )}

          <NavDropdown title="Logout" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/" onClick={handleLogoutClick}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>

        <Nav className="plant-select">
          {userId === '1' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} /> : Bodyknits
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} />: User
              </Nav.Item>
            </>
          )}
          {userId === '2' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} /> : Sweelee
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} />:  User
              </Nav.Item>
            </>
          )}
          {userType === '4' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} /> :TRE-Solutions
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} /> : User
              </Nav.Item>
            </>
          )}
          {userType === '9' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} /> : TRE-Solutions
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} /> : Super Admin
              </Nav.Item>
            </>
          )}
          {userType === '3' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} /> : Owner
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} /> : Owner
              </Nav.Item>
            </>
          )}
          {userType === '2' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} />: Admin
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} />: Admin
              </Nav.Item>
            </>
          )}

          {userType === '5' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} /> : TRE-Solutions
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} />: Admin
              </Nav.Item>
            </>
          )}

          {!isFinanceModuleActive && ( // Show plant selection only if not in finance module
            // <Nav.Item>
            //   <img src={solarpanel} alt="solarpanel" style={iconStyle} />
            //   :
            //   <select
            //     id="PlantAdmin"
            //     onChange={handlePlantChange}
            //     value={userType === '4' ? 'Bodyknits' : selectedPlant}
            //     className="form-control"
            //   >
            //     {availablePlants.map((plant) => (
            //       <option key={plant} value={plant}>
            //         {plant === 'Bodyknits' && userType === '4' ? 'Plant A' : displayNameMapping[plant] || plant}
            //       </option>
            //     ))}
            //   </select>
            // </Nav.Item>
            <Nav.Item>
  <img src={solarpanel} alt="solarpanel" style={iconStyle} />
  :
  <select
    id="PlantAdmin"
    onChange={handlePlantChange}
    value={selectedPlant} // Use selectedPlant directly
    className="form-control"
  >
    {availablePlants.map((plant) => (
      <option key={plant} value={plant}>
        {plant === 'Bodyknits' && (userType === '4' || userType === '5') ? 'Plant A' : 
         plant === 'Sweelee' && userType === '5' ? 'Plant B' : 
         displayNameMapping[plant] || plant}
      </option>
    ))}
  </select>
</Nav.Item>

          )}

          {userType !== '1' && userType !== '2' && userType !== '3' && userType !== '4' && userType !=='5' && (
            <Nav.Item className="nav-item">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="user-management-tooltip">User Management</Tooltip>}
              >
                <Link to="/userinfo">
                  <FontAwesomeIcon icon={faUserCog} style={userIconStyle} />
                </Link>
              </OverlayTrigger>
            </Nav.Item>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
