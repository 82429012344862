// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.financial-module .header {
  display: flex;
  justify-content: center;
  padding: 20px;
  /* background-color: #f5f5f5; */
}

.financial-module .header button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color:#0e740f;
  color: white;
  font-size: 16px;
}

.financial-module .header button:hover {
  background-color: #0d720f;
}

.content-finance {
  padding: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/components/NEW FM/NewFMFinancial.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,+BAA+B;AACjC;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,wBAAwB;EACxB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".financial-module .header {\r\n  display: flex;\r\n  justify-content: center;\r\n  padding: 20px;\r\n  /* background-color: #f5f5f5; */\r\n}\r\n\r\n.financial-module .header button {\r\n  margin: 0 10px;\r\n  padding: 10px 20px;\r\n  cursor: pointer;\r\n  border: none;\r\n  background-color:#0e740f;\r\n  color: white;\r\n  font-size: 16px;\r\n}\r\n\r\n.financial-module .header button:hover {\r\n  background-color: #0d720f;\r\n}\r\n\r\n.content-finance {\r\n  padding: 20px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
