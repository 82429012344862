
// import React, { useEffect, useState } from 'react';
// import ReactECharts from 'echarts-for-react';
// import axios from 'axios';

// const LineChart = ({ apiUrl, date, plant }) => {
//   const [chartData, setChartData] = useState({ xAxis: [], irradianceSeries: [], powerOutSeries: [] });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.post(apiUrl, { Date: date, plant });
//         const { xAxis, 'Irradiance W/m2': irradianceData, 'PowerOut kW': powerOutData } = response.data.data;

//         setChartData({
//           xAxis,
//           irradianceSeries: irradianceData,
//           powerOutSeries: powerOutData,
//         });
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [apiUrl, date, plant]);

//   const getOption = () => ({
//     color: ['#000000', '#ff0000'], 
//     backgroundColor: 'white',
//     grid: {
//       left: '8%',
//       right: '6%',
//       bottom: '3%',
//       top: '22%',
//       containLabel: true,
//     },
//     tooltip: {
//       trigger: 'axis',
//       axisPointer: {
//         type: 'cross',
//         label: {
//           backgroundColor: '#6a7985',
//         },
//       },
//     },
//     toolbox: {
//       feature: {
//         saveAsImage: {},
//       },
//     },
//     legend: {
//       data: ['Irradiance W/m²', 'PowerOut kW'],
//       textStyle: {
//         color: 'black',
//       },
//     },
//     xAxis: {
//       type: 'category',
//       boundaryGap: 'false',
//       axisLine: {
//         lineStyle: {
//           color: 'rgba(255,255,255,.2)',
//         },
//       },
//       splitLine: {
//         lineStyle: {
//           color: 'rgba(255,255,255,.1)',
//         },
//       },
//       axisLabel: {
//         color: 'black',
//         rotate: 1, 
//         margin: 15,
//       },
//       data: chartData.xAxis,
//     },
//     yAxis: [
//       {
//         type: 'value',
//         name: 'PowerOut kW',
//         nameLocation: 'end',
//         nameGap: 15, 
//         nameTextStyle: {
//           color: 'black',
//           fontSize: 12,
//           fontWeight: 'bold',
//          align: 'center',
//         },
//         position: 'left',
//         axisLine: {
//           lineStyle: {
//             color: 'rgba(255,255,255,.2)',
//           },
//         },
//         splitLine: {
//           lineStyle: {
//             color: 'rgba(255,255,255,.1)',
//           },
//         },
//         axisLabel: {
//           color: 'black',
//         },
//       },
//       {
//         type: 'value',
//         name: 'Irradiance W/m²',
//         nameLocation: 'end',
//         nameGap: 15, 
//         nameTextStyle: {
//           color: 'black',
//           fontSize: 12,
//           fontWeight: 'bold',
//           align: 'center',
//         },
//         position: 'right',
//         axisLine: {
//           lineStyle: {
//             color: 'rgba(255,255,255,.2)',
//           },
//         },
//         splitLine: {
//           lineStyle: {
//             color: 'rgba(255,255,255,.1)',
//           },
//         },
//         axisLabel: {
//           color: 'black',
//         },
//       },
//     ],
//     series: [
//       {
//         name: 'Irradiance W/m²',
//         type: 'line',
//         areaStyle: { color: 'rgba(0, 0, 0, 0.1)' }, 
//         data: chartData.irradianceSeries,
//         yAxisIndex: 1, 
//       },
//       {
//         name: 'PowerOut kW',
//         type: 'line',
//         areaStyle: { color: 'rgba(255, 0, 0, 0.3)' }, 
//         data: chartData.powerOutSeries,
//         yAxisIndex: 0, 
//       },
//     ],
//   });

//   return <ReactECharts option={getOption()} />;
// };

// export default LineChart;
import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';

const LineChart = ({ apiUrl, date, plant }) => {
  const [chartData, setChartData] = useState({ xAxis: [], irradianceSeries: [], powerOutSeries: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(apiUrl, { Date: date, plant });
        const { xAxis, 'Irradiance W/m2': irradianceData, 'PowerOut kW': powerOutData } = response.data.data;

        setChartData({
          xAxis: xAxis.length ? xAxis : ['No Data'], // Placeholder when no data is available
          irradianceSeries: irradianceData.length ? irradianceData : [0], // Placeholder for missing data
          powerOutSeries: powerOutData.length ? powerOutData : [0], // Placeholder for missing data
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        // Set empty data in case of error to avoid breaking the graph
        setChartData({
          xAxis: ['No Data'], 
          irradianceSeries: [0], 
          powerOutSeries: [0],
        });
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 300000); // 300,000 ms = 5 minutes
        return () => clearInterval(intervalId);

  }, [apiUrl, date, plant]);

  const getOption = () => ({
    color: ['#000000', '#ff0000'],
    backgroundColor: 'white',
    grid: {
      left: '8%',
      right: '6%',
      bottom: '3%',
      top: '22%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    legend: {
      data: ['Irradiance W/m²', 'PowerOut kW'],
      textStyle: {
        color: 'black',
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.2)',
        },
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.1)',
        },
      },
      axisLabel: {
        color: 'black',
        rotate: 1,
        margin: 15,
      },
      data: chartData.xAxis,
    },
    yAxis: [
      {
        type: 'value',
        name: 'PowerOut kW',
        nameLocation: 'end',
        nameGap: 15,
        nameTextStyle: {
          color: 'black',
          fontSize: 12,
          fontWeight: 'bold',
          align: 'center',
        },
        position: 'left',
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.2)',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)',
          },
        },
        axisLabel: {
          color: 'black',
        },
      },
      {
        type: 'value',
        name: 'Irradiance W/m²',
        nameLocation: 'end',
        nameGap: 15,
        nameTextStyle: {
          color: 'black',
          fontSize: 12,
          fontWeight: 'bold',
          align: 'center',
        },
        position: 'right',
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.2)',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)',
          },
        },
        axisLabel: {
          color: 'black',
        },
      },
    ],
    series: [
      {
        name: 'Irradiance W/m²',
        type: 'line',
        areaStyle: { color: 'rgba(0, 0, 0, 0.1)' },
        data: chartData.irradianceSeries,
        yAxisIndex: 1,
      },
      {
        name: 'PowerOut kW',
        type: 'line',
        areaStyle: { color: 'rgba(255, 0, 0, 0.3)' },
        data: chartData.powerOutSeries,
        yAxisIndex: 0,
      },
    ],
  });

  return <ReactECharts option={getOption()} />;
};

export default LineChart;
