// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.solar-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  .solar-table th,
  .solar-table td {
    border: 2px solid #ddd;
    padding: 5px;
    text-align: center;
  }
  
  .solar-header {
    font-weight: bold;
  }
  .solar-table input[type="text"] {
    width: 90%;
    padding: 2px;
    box-sizing: border-box;
    border: 2px solid #ccc;
  }
  
  .solar-table input[type="text"]:focus {
    border-color: #007bff;
    outline: none;
  }`, "",{"version":3,"sources":["webpack://./src/components/NEW FM/Page2/SolarTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB;EACA;IACE,UAAU;IACV,YAAY;IACZ,sBAAsB;IACtB,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;IACrB,aAAa;EACf","sourcesContent":[".solar-table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n    background-color: #fff;\r\n  }\r\n  \r\n  .solar-table th,\r\n  .solar-table td {\r\n    border: 2px solid #ddd;\r\n    padding: 5px;\r\n    text-align: center;\r\n  }\r\n  \r\n  .solar-header {\r\n    font-weight: bold;\r\n  }\r\n  .solar-table input[type=\"text\"] {\r\n    width: 90%;\r\n    padding: 2px;\r\n    box-sizing: border-box;\r\n    border: 2px solid #ccc;\r\n  }\r\n  \r\n  .solar-table input[type=\"text\"]:focus {\r\n    border-color: #007bff;\r\n    outline: none;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
