import React from 'react';
import '../OverviewStyle.css'; // Ensure the CSS file is imported

const EnergyValue = ({ viewOption, energyValue, dailyIrradiance }) => {
    return (
        <div className="energy-value-container">
            <h6>
                {viewOption} Yield: <strong>{energyValue}</strong> {viewOption === 'Day' ? 'kWh' : 'MWh'}
            </h6>
            {viewOption === 'Day' && dailyIrradiance && (
                <h6>
                    Daily Irradiance: <strong>{dailyIrradiance}</strong> kWh/m²
                </h6>
            )}
        </div>
    );
};

export default EnergyValue;
