
import React, { useState } from 'react';
import './Dashboard.css'; 
import ProfitLoss from './ProfiLoss';
import SolarTable from './SolarTable';
import PPATable from './PPATable';
import RECTable from './RECTable';

const Dashboard = () => {
 
  const [activeTable, setActiveTable] = useState('solar');  

  return (
    <div>
      <h3 style={{ color: "red" }}>TREK RENEWABLE ENERGY SOLUTIONS PTE. LTD.</h3>
      <h4> Swee Lee Project</h4>
      
      <div className="dashboard-container">
        <div className="right-table-container">
          <ProfitLoss />
        </div>

        <div className="left-table-container">
          <div className="button-container">
            <button
        onClick={() => setActiveTable('solar')}
        className={activeTable === 'solar' ? 'active' : ''}
        style={{ marginRight: '10px' }}
         >
        Solar Generation
    </button>

    <button
        onClick={() => setActiveTable('ppa')}
        className={activeTable === 'ppa' ? 'active' : ''}
        style={{ marginRight: '10px' }}
    >
         PPA Revenue
    </button>

    <button
        onClick={() => setActiveTable('rec')}
        className={activeTable === 'rec' ? 'active' : ''}
    >
         REC Revenue
    </button>
</div>
          
          {activeTable === 'solar' && <SolarTable />}
          {activeTable === 'ppa' && <PPATable />}
          {activeTable === 'rec' && <RECTable />}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
