import React from 'react';

const Dropdown = ({ viewOption, handleOptionChange }) => {
    return (
        <div className="dropdown-container">
            <select
                value={viewOption}
                onChange={handleOptionChange}
                className="styled-dropdown"
            >
                <option value="Day">Day</option>
                <option value="Month">Month</option>
                <option value="Year">Year</option>
                <option value="Lifetime">Lifetime</option>
            </select>
        </div>
    );
};

export default Dropdown;
