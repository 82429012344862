// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .navbar-custom {
      display: flex;
      justify-content: space-between; 
      align-items: center;
    }
    
    .navbar-custom .nav {
      flex: 1 1;
      display: flex; 
    }
    
    .navbar-custom .nav .nav-item {
      margin-right: 10px; 
    }
    
    .navbar-custom .plant-select {
      display: flex;
      align-items: center;
      gap: 10px; 
      margin-left: auto;
    }
    
    .nav-item {
      display: flex;
      align-items: center;
    }
    
    .form-control {
      margin-left: 10px; 
    }
    `, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":";IACI;MACE,aAAa;MACb,8BAA8B;MAC9B,mBAAmB;IACrB;;IAEA;MACE,SAAO;MACP,aAAa;IACf;;IAEA;MACE,kBAAkB;IACpB;;IAEA;MACE,aAAa;MACb,mBAAmB;MACnB,SAAS;MACT,iBAAiB;IACnB;;IAEA;MACE,aAAa;MACb,mBAAmB;IACrB;;IAEA;MACE,iBAAiB;IACnB","sourcesContent":["\r\n    .navbar-custom {\r\n      display: flex;\r\n      justify-content: space-between; \r\n      align-items: center;\r\n    }\r\n    \r\n    .navbar-custom .nav {\r\n      flex: 1;\r\n      display: flex; \r\n    }\r\n    \r\n    .navbar-custom .nav .nav-item {\r\n      margin-right: 10px; \r\n    }\r\n    \r\n    .navbar-custom .plant-select {\r\n      display: flex;\r\n      align-items: center;\r\n      gap: 10px; \r\n      margin-left: auto;\r\n    }\r\n    \r\n    .nav-item {\r\n      display: flex;\r\n      align-items: center;\r\n    }\r\n    \r\n    .form-control {\r\n      margin-left: 10px; \r\n    }\r\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
