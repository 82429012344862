
import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';

const YearBarChart = ({ apiUrl,Date, plant }) => {
    const [chartData, setChartData] = useState({
        xAxis: [],
        irradiationSeries: [],
        yieldSeries: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(apiUrl, {
                    plant,
                    Date
                });
                
                if (response.data.success) {
                    const { xAxis, 'Irradiation kWh/m2': irradiationData, 'Yield kWh': yieldData } = response.data.data;
                    
                    setChartData({
                        xAxis,
                        irradiationSeries: irradiationData,
                        yieldSeries: yieldData,
                    });
                } else {
                    console.error('API response error:', response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [apiUrl, Date, plant]);

    const getOption = () => ({
        color: ['#0000ff', '#ff0000'],
        backgroundColor: 'white',
        grid: {
            left: '3%',
            right: '8%',
            bottom: '3%',
            top: '22%',
            containLabel: true,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },toolbox: {
            feature: {
              saveAsImage: {},
            },
          },

        legend: {
            data: ['Irradiation kWh/m²', 'Yield kWh'],
        },
        xAxis: {
            type: 'category',
            data: chartData.xAxis,
            axisLabel: {
                color: 'black',
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                },
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                },
            },
        },
        yAxis: [
            {
                type: 'value',
                name: 'Yield kWh',
                nameLocation: 'end',
                nameGap: 15, 
                nameTextStyle: {
                  color: 'black',
                  fontSize: 12,
                  fontWeight: 'bold',
                 align: 'center',
                },
                position: 'left',
                axisLine: {
                  lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                  },
                },
                splitLine: {
                  lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                  },
                },
                axisLabel: {
                  color: 'black',
                },
              },
              {
                type: 'value',
                name: 'Irradiation kWh/m²',
                nameLocation: 'end',
                nameGap: 15, 
                nameTextStyle: {
                  color: 'black',
                  fontSize: 12,
                  fontWeight: 'bold',
                  align: 'center',
                
                },
                position: 'right',
                axisLine: {
                  lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                  },
                },
                splitLine: {
                  lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                  },
                },
                axisLabel: {
                  color: 'black',
                },
              },
            ],
        series: [
            {
                name: 'Irradiation kWh/m²',
                type: 'bar',
                data: chartData.irradiationSeries,
                yAxisIndex: 1,
            },
            {
                name: 'Yield kWh',
                type: 'bar',
                data: chartData.yieldSeries,
                yAxisIndex: 0,
            },
        ],
    });

    return <ReactECharts option={getOption()} />;
};

export default YearBarChart;
