import React, { useState } from 'react';
import ProjectSummary from './Page1/ProjectSummary';
import Dashboard from './Page2/Dashboard';
import './NewFMFinancial.css';

const FinancialModule = () => {
  const [activeComponent, setActiveComponent] = useState('ProjectSummary');

  const renderComponent = () => {
    switch (activeComponent) {
      case 'ProjectSummary':
        return <ProjectSummary />;
      case 'DashboardDisplay':
        return <Dashboard />;
      default:
        return <ProjectSummary />;
    }
  };

  return (
    <div className="financial-module">
     
      <div className="content-finance">
        {renderComponent()}
      </div>
      <div className="header">
        <button onClick={() => setActiveComponent('ProjectSummary')}>Page 1</button>
        <button onClick={() => setActiveComponent('DashboardDisplay')}>Page 2</button>
      </div>
    </div>
  );
};

export default FinancialModule;
