import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';

const LifetimeChartGraph2 = ({ apiUrl,  plant }) => {
    const [chartData, setChartData] = useState({
        xAxis: [],
        yieldSeries: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(apiUrl, {
                    plant,
                
                });
                if (response.status === 200 && response.data) {
                    const { xAxis, 'Yield kWh': yieldData } = response.data.data;

                    setChartData({
                        xAxis,
                        yieldSeries: yieldData,
                    });
                } else {
                    console.error('API response error:', response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error.message, error.response ? error.response.data : '');
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 300000); // 300,000 ms = 5 minutes
        return () => clearInterval(intervalId);

    }, [apiUrl,plant]);

    const getOption = () => ({
        color: ['#00ff00'],
        backgroundColor: 'white',
        grid: {
            left: '3%',
            right: '8%',
            bottom: '3%',
            top: '22%',
            containLabel: true,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        legend: {
            data: ['Yield kWh'],
        },
        xAxis: {
            type: 'category',
            data: chartData.xAxis,
            axisLabel: {
                color: 'black',
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                },
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                },
            },
        },
        yAxis: {
            type: 'value',
            name: 'Yield kWh',
            nameLocation: 'end',
            nameGap: 15,
            nameTextStyle: {
                color: 'black',
                fontSize: 12,
                fontWeight: 'bold',
                align: 'center',
            },
            position: 'left',
            axisLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                },
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                },
            },
            axisLabel: {
                color: 'black',
            },
        },
        series: [
            {
                name: 'Yield kWh',
                type: 'bar',
                data: chartData.yieldSeries,
            },
        ],
    });

    return <ReactECharts option={getOption()} />;
};

export default LifetimeChartGraph2;
