
import React from 'react';

import { Tooltip } from 'react-tooltip';

const FinancialIndicators = ({ showFinancialTable, toggleFinancialTableVisibility, projectInfo, handleInputChange }) => {
  return (
    <div>
      {showFinancialTable ? (
        <button
        style={{
          fontSize: '16px',
          color: 'white',
          backgroundColor: 'green',  
          border: 'none',
          padding: '5px 15px',
          cursor: 'pointer',
          borderRadius: '5px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={toggleFinancialTableVisibility}
      >
        Close
      </button>
      

      ) : (
        <>
         
      <span
  style={{
    fontSize: '20px',
    cursor: 'pointer',
    float: "left",
    marginLeft: '10px',
  }}
  onClick={toggleFinancialTableVisibility}
>

</span>

<button
  data-tooltip-id="plus-tooltip"
  data-tooltip-content="Click to view the table"
  style={{
    fontSize: '16px',
    color: 'white',
    backgroundColor: 'green',
    border: 'none',
    padding: '5px 15px',
    cursor: 'pointer',
    float: 'left',
    marginLeft: '18px',
    borderRadius: '5px',
  }}
  onClick={toggleFinancialTableVisibility}
>
  View 
</button>

      <Tooltip id="plus-tooltip" place="top" effect="solid" />
          <span style={{ fontSize: '20px', cursor: 'pointer',float:"left", marginLeft: '10px' }} onClick={toggleFinancialTableVisibility}>
            Financial Indicators
          </span>
        </>
      )}

      {showFinancialTable && (
        <div className="left-column">
          <h4>Financial Indicators</h4>
          <table>
            <thead>
    <tr>
      <th rowSpan="2">Financial Indicators</th>
      <th rowSpan="2">Projected Total Value (Contract period)</th>
      <th colSpan="4" style={{textAlign:"center"}}>Actual Values</th>
    </tr>
    <tr>
      <th>Monthly</th>
      <th>Yearly</th>
      <th>Year to Date</th>
      <th>Total Project Value (Contract Period)</th>
    </tr>
  </thead>
  <tbody>
              <tr>
                <td>1. Initial Investments (S$)</td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
              </tr>
              <tr>
                <td>2(a). Present Value @Discount Rate of 3% p.a. (Over 10 years) (S$)</td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
              </tr>
              <tr>
                <td>(b). Net Present Value @Discount Rate of 3% p.a. (Over 10 years) (S$)</td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
              </tr>
              <tr>
                <td>3. Payback Period (No. of years)</td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
              </tr>
              <tr>
                <td>4. Internal Rate of Return (%) (Over 10 years) (NPV=0)</td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
              </tr>
              <tr>
                <td>5. Return on Capital Employed (%) (Average over 10 years)</td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
              </tr>
              <tr>
                <td>6. Return on Investment (%) (Average over 10 years)</td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
              </tr>
            </tbody> 
          </table>
        </div>
      )}
    </div>
  );
};

export default FinancialIndicators;
