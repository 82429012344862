
// import React, { useEffect, useState, useCallback } from 'react';
// import axios from 'axios';

// import critical from '../../../img/critical.png'; 
// import major from '../../../img/major.png';
// import minor from '../../../img/minor.png';
// import warning from '../../../img/warning.png';

// const AlarmIndicators = ({ selectedPlant }) => {
//   const [alarmData, setAlarmData] = useState({
//     A1: 0, // Critical
//     A2: 0, // Major
//     A3: 0, // Minor
//     A4: 0, // Warning
//   });
//   const [loading, setLoading] = useState(false);

//   const fetchAlarmData = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await axios.post('/api/alarmdetails', {
//         plant: selectedPlant
//       });
//       if (response.data && response.data.success) {
//         setAlarmData(response.data.data);
//       }
//     } catch (error) {
//       console.error('Error fetching alarm data:', error);
//     } finally {
//       setLoading(false);
//     }
//   }, [selectedPlant]); 

//   useEffect(() => {
//     if (selectedPlant) {
//       fetchAlarmData();
//     }
//   }, [selectedPlant, fetchAlarmData]);

//   return (
//     <div className='alarm-container'>
//       <div className="alarm-header">
//         <h6>Alarm</h6>
//       </div>

//       {loading ? (
//         <p></p>
//       ) : (
//         <div className="alarm-grid">
//           <div className="alarm-item critical">
//             <span>
//               <img src={critical} alt="critical" className="alarm-icon" />
//               Critical: 
//             </span>
//             <span>{alarmData.A1}</span>
//           </div>
//           <div className="alarm-item major">
//             <span>
//               <img src={major} alt="major" className="alarm-icon" />
//               Major: 
//             </span>
//             <span>{alarmData.A2}</span>
//           </div>
//           <div className="alarm-item minor">
//             <span>
//               <img src={minor} alt="minor" className="alarm-icon" />
//               Minor: 
//             </span>
//             <span>{alarmData.A3}</span>
//           </div>
//           <div className="alarm-item warning">
//             <span>
//               <img src={warning} alt="warning" className="alarm-icon" />
//               Warning: 
//             </span>
//             <span>{alarmData.A4}</span>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AlarmIndicators;



import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';

import critical from '../../../img/critical.png'; 
import major from '../../../img/major.png';
import minor from '../../../img/minor.png';
import warning from '../../../img/warning.png';

const AlarmIndicators = ({ plant }) => {
  const [alarmData, setAlarmData] = useState({
    A1: 0, // Critical
    A2: 0, // Major
    A3: 0, // Minor
    A4: 0, // Warning
  });
  const [loading, setLoading] = useState(false);

  const fetchAlarmData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/alarmdetails', {
        plant: plant
      });
      

      if (response.data && response.data.success) {
        setAlarmData(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching alarm data:', error);
    } finally {
      setLoading(false);
    }
  }, [plant]); 

  useEffect(() => {
    // console.log('Sele Plant:',plant);
    if (plant) {
      fetchAlarmData();
    }
  }, [plant, fetchAlarmData]);

  return (
    <div className='alarm-container'>
      <div className="alarm-header">
        <h6>Alarm</h6>
      </div>

      {loading ? (
        <p></p>
      ) : (
        <div className="alarm-grid">
          <div className="alarm-item critical">
            <span>
              <img src={critical} alt="critical" className="alarm-icon" />
              Critical: 
            </span>
            <span>{alarmData.A1}</span>
          </div>
          <div className="alarm-item major">
            <span>
              <img src={major} alt="major" className="alarm-icon" />
              Major: 
            </span>
            <span>{alarmData.A2}</span>
          </div>
          <div className="alarm-item minor">
            <span>
              <img src={minor} alt="minor" className="alarm-icon" />
              Minor: 
            </span>
            <span>{alarmData.A3}</span>
          </div>
          <div className="alarm-item warning">
            <span>
              <img src={warning} alt="warning" className="alarm-icon" />
              Warning: 
            </span>
            <span>{alarmData.A4}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlarmIndicators;
